import React from 'react';
import './App.css';
import ClientList from './components/ClientList';
import AssistantList from './components/AssistantList';
import CaptureCreation from './components/CaptureCreation';
import Login from './Login';
import { AuthProvider, useAuth } from './AuthContext';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Login />;
};

function Dashboard() {
  const [activeComponent, setActiveComponent] = React.useState('clientList');
  const { logout } = useAuth();

  const renderComponent = () => {
    switch(activeComponent) {
      case 'clientList':
        return <ClientList />;
      case 'assistantList':
        return <AssistantList />;
      case 'createCapture':
        return <CaptureCreation />;
      default:
        return <ClientList />;
    }
  };

  return (
    <div className="App">
      <nav>
        <button onClick={() => setActiveComponent('clientList')}>Client List</button>
        <button onClick={() => setActiveComponent('assistantList')}>Assistant List</button>
        <button onClick={() => setActiveComponent('createCapture')}>Create Capture</button>
        <button onClick={logout}>Logout</button>
      </nav>
      {renderComponent()}
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    </AuthProvider>
  );
}

export default App;