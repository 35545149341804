import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AssistantList = () => {
  const [assistants, setAssistants] = useState([]);
  const [filteredAssistants, setFilteredAssistants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'descending' });
  const [modelOptions, setModelOptions] = useState([]);
  const [selectedModels, setSelectedModels] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchAssistants = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}v1/assistant/retrieve_all`, {
          params: {
            authorization: process.env.REACT_APP_API_AUTH_KEY
          }
        });
        setAssistants(response.data.data);
        setFilteredAssistants(response.data.data);
        
        // Generate unique model options
        const uniqueModels = [...new Set(response.data.data.map(assistant => assistant.model))];
        setModelOptions(uniqueModels);
        
        setLoading(false);
      } catch (err) {
        setError('Error fetching assistants');
        setLoading(false);
      }
    };

    fetchAssistants();
  }, []);

  useEffect(() => {
    let results = assistants;

    // Filter by search term
    if (searchTerm) {
      results = results.filter(assistant => 
        assistant.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Filter by selected models
    if (selectedModels.length > 0) {
      results = results.filter(assistant => selectedModels.includes(assistant.model));
    }

    // Apply sorting
    results.sort((a, b) => {
      if (sortConfig.key === 'created_at' || sortConfig.key === 'last_modified') {
        return sortConfig.direction === 'ascending' 
          ? new Date(a[sortConfig.key]) - new Date(b[sortConfig.key])
          : new Date(b[sortConfig.key]) - new Date(a[sortConfig.key]);
      }

      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setFilteredAssistants(results);
  }, [assistants, sortConfig, selectedModels, searchTerm]);

  const handleSort = (key) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'ascending' ? 'descending' : 'ascending'
    }));
  };

  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? ' ↑' : ' ↓';
    }
    return '';
  };

  const handleModelChange = (event) => {
    const value = Array.from(
      event.target.selectedOptions,
      option => option.value
    );
    setSelectedModels(value);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <h2>Assistant List</h2>
      <div style={{ marginBottom: '20px' }}>
        <input
          type="text"
          placeholder="Search assistants by name..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginRight: '10px' }}
        />
        <label style={{ marginRight: '10px' }}>Filter by model:</label>
        <br></br>
        <select
          multiple
          value={selectedModels}
          onChange={handleModelChange}
          style={{ width: '200px' }}
        >
          {modelOptions.map(model => (
            <option key={model} value={model}>
              {model}
            </option>
          ))}
        </select>
      </div>
      <table>
        <thead>
          <tr>
            <th onClick={() => handleSort('name')} style={{ cursor: 'pointer' }}>
              Name{getSortIndicator('name')}
            </th>
            <th onClick={() => handleSort('client_id')} style={{ cursor: 'pointer' }}>
              Client ID{getSortIndicator('client_id')}
            </th>
            <th onClick={() => handleSort('assistant_id')} style={{ cursor: 'pointer' }}>
              Assistant ID{getSortIndicator('assistant_id')}
            </th>
            <th onClick={() => handleSort('model')} style={{ cursor: 'pointer' }}>
              Model{getSortIndicator('model')}
            </th>
            <th onClick={() => handleSort('created_at')} style={{ cursor: 'pointer' }}>
              Created At{getSortIndicator('created_at')}
            </th>
            <th onClick={() => handleSort('last_modified')} style={{ cursor: 'pointer' }}>
              Last Modified{getSortIndicator('last_modified')}
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredAssistants.map((assistant) => (
            <tr key={assistant.assistant_id}>
              <td>{assistant.name}</td>
              <td>{assistant.client_id}</td>
              <td>{assistant.assistant_id}</td>
              <td>{assistant.model}</td>
              <td>{new Date(assistant.created_at).toLocaleString()}</td>
              <td>{new Date(assistant.last_modified).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AssistantList;