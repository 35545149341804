import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const AUTH = process.env.REACT_APP_API_AUTH_KEY;


const api = axios.create({
  baseURL: BASE_URL,
  params: { authorization: AUTH },
});

export const createClient = (clientData) => api.post('v1/client/create', clientData);

export const createAssistant = (assistantData) => {
  console.log('Sending assistant data to API:', assistantData);
  return api.post('v1/assistant/create', assistantData, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export const startChat = () => api.get('v1/chat/start');
export const sendChatMessage = (messageData) => api.post('v1/chat/', messageData);
export const setFirebaseUser = (userData) => api.post('v1/firestore/set_fb_user', userData);

export const createScrapingSettings = (settingsData, assistantId) => {
  return api.post('v1/firestore/create_scraping', settingsData, {
    params: { assistant_id: assistantId }
  });
};